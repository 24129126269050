
    import './styles.scoped.css';
    export default {
  "content": "awsui_content_vjswe_uyta3_153",
  "button": "awsui_button_vjswe_uyta3_157",
  "variant-normal": "awsui_variant-normal_vjswe_uyta3_205",
  "disabled": "awsui_disabled_vjswe_uyta3_223",
  "variant-primary": "awsui_variant-primary_vjswe_uyta3_230",
  "variant-link": "awsui_variant-link_vjswe_uyta3_255",
  "variant-icon": "awsui_variant-icon_vjswe_uyta3_280",
  "variant-inline-icon": "awsui_variant-inline-icon_vjswe_uyta3_305",
  "variant-inline-icon-pointer-target": "awsui_variant-inline-icon-pointer-target_vjswe_uyta3_330",
  "variant-inline-link": "awsui_variant-inline-link_vjswe_uyta3_355",
  "variant-modal-dismiss": "awsui_variant-modal-dismiss_vjswe_uyta3_384",
  "variant-flashbar-icon": "awsui_variant-flashbar-icon_vjswe_uyta3_409",
  "variant-breadcrumb-group": "awsui_variant-breadcrumb-group_vjswe_uyta3_434",
  "variant-menu-trigger": "awsui_variant-menu-trigger_vjswe_uyta3_464",
  "button-no-text": "awsui_button-no-text_vjswe_uyta3_556",
  "button-no-wrap": "awsui_button-no-wrap_vjswe_uyta3_560",
  "full-width": "awsui_full-width_vjswe_uyta3_563",
  "icon-left": "awsui_icon-left_vjswe_uyta3_585",
  "icon-right": "awsui_icon-right_vjswe_uyta3_590",
  "icon": "awsui_icon_vjswe_uyta3_585",
  "link": "awsui_link_vjswe_uyta3_609",
  "disabled-with-reason": "awsui_disabled-with-reason_vjswe_uyta3_609"
};
  